import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import { Formik, Field, Form } from "formik";
import { TextField as FormikMUTextField } from "formik-material-ui";
import Wrapper from "./wrapper";
import { VID } from "../../project-config.js";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import * as Yup from "yup";
import { shallowEqual, useSelector } from "react-redux";
import { phoneRegExp } from "./utils";

const MyAccountBillingAddress = () => {
  const securityTokenState = useSelector(
    state => state.loginReducer.securityToken,
    shallowEqual
  );
  const [accountState, setAccountState] = useState();
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const Alert = props => <MuiAlert elevation={6} variant="filled" {...props} />;

  const Schema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .matches(/^[a-zA-Z]+$/, "Letters only")
      .required("Required"),
    lastName: Yup.string()
      .trim()
      .matches(/^[a-zA-Z]+$/, "Letters only")
      .required("Required"),
    city: Yup.string()
      .trim()
      .required()
      .matches(/^[a-zA-Z]+$/, "Letters only")
      .required("Required"),
    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    title: Yup.string().trim().required("Required"),
    company: Yup.string().trim().required("Required"),
    address1: Yup.string().trim().required("Required"),
    address2: Yup.string().trim(),
    postalCode: Yup.string().trim().required("Required")
  });

  useEffect(() => {
    axios
      .get(
        `${process.env.GATSBY_PREVIEW_PROJECT_LINK}/myaccount.html?mode=billingaddress&vid=${VID}&tpt=json_en`,
        { withCredentials: true, responseType: "json" }
      )
      .then(res => setAccountState(res.data));
  }, []);

  const handleSubmitForm = val => {
    const {
      title,
      firstName,
      lastName,
      company,
      address1,
      address2,
      provinceID,
      city,
      postalCode,
      phone,
      addressId,
      countryID
    } = val;
    let form = new FormData();
    form.append("securitytoken", securityTokenState);
    form.append("billingAddress.title", title);
    form.append("billingAddress.firstname", firstName);
    form.append("billingAddress.lastname", lastName);
    form.append("billingAddress.company", company);
    form.append("billingAddress.address1", address1);
    form.append("billingAddress.address2", address2);
    form.append("billingAddress.countryid", countryID);
    form.append("billingAddress.provinceid", provinceID);
    form.append("billingAddress.anotherprovince", "");
    form.append("billingAddress.city", city);
    form.append("billingAddress.postal", postalCode);
    form.append("billingAddress.phone", phone);
    form.append("billingAddress.addressid", addressId);
    form.append("submit", "update");
    form.append(
      "_targetbillingaddress",
      `myaccount.html?vid=${VID}&mode=billingaddress`
    );
    form.append("TODO", "");

    axios({
      method: "post",
      data: form,
      url: `${process.env.GATSBY_PREVIEW_PROJECT_LINK}/myaccount.html?&vid=${VID}&mode=billingaddress`,
      responseType: "document",
      withCredentials: true
    }).then(() => setOpen(true));
  };

  return (
    <Wrapper
      component={
        <>
          <Formik
            initialValues={{
              addressId: accountState?.addressInfo.addressId || "",
              title: accountState?.addressInfo.title || "",
              company: accountState?.addressInfo.company || "",
              firstName: accountState?.addressInfo.firstName || "",
              lastName: accountState?.addressInfo.lastName || "",
              address1: accountState?.addressInfo.address1 || "",
              address2: accountState?.addressInfo.address2 || "",
              city: accountState?.addressInfo.city || "",
              postalCode: accountState?.addressInfo.postalCode || "",
              phone: accountState?.addressInfo.phone || "",
              countries: accountState?.countries || [],
              countryID: accountState?.addressInfo.countryID || "",
              provinceID: accountState?.addressInfo.provinceID || ""
            }}
            validationSchema={Schema}
            enableReinitialize
            onSubmit={async values => handleSubmitForm(values)}
          >
            <Form>
              <Typography variant="h3" gutterBottom>
                Billing Address
              </Typography>
              <Divider />
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Field
                    component={FormikMUTextField}
                    label="Title"
                    name="title"
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    component={FormikMUTextField}
                    label="Company"
                    name="company"
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    component={FormikMUTextField}
                    label="First name"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    component={FormikMUTextField}
                    label="Last name"
                    name="lastName"
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    component={FormikMUTextField}
                    label="Address 1"
                    name="address1"
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    component={FormikMUTextField}
                    label="Address 2"
                    name="address2"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    component={FormikMUTextField}
                    label="City"
                    name="city"
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth>
                    <Field
                      name="countryID"
                      variant="outlined"
                      label="Country"
                      select
                      component={FormikMUTextField}
                      children={
                        accountState?.countries?.map(obj => (
                          <MenuItem key={obj.country_id} value={obj.country_id}>
                            {obj.country_name}
                          </MenuItem>
                        )) || <MenuItem value={1}>1</MenuItem>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth>
                    <Field
                      variant="outlined"
                      name="provinceID"
                      label="State/Province"
                      select
                      component={FormikMUTextField}
                      children={
                        accountState?.provinces?.province_list?.map(obj => (
                          <MenuItem
                            key={obj.province_id}
                            value={obj.province_id}
                          >
                            {obj.province_name}
                          </MenuItem>
                        )) || <MenuItem value={1}>1</MenuItem>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    component={FormikMUTextField}
                    label="Postal code"
                    name="postalCode"
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    component={FormikMUTextField}
                    label="Phone"
                    name="phone"
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>
              <br />
              <Divider />
              <br />
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<SaveIcon />}
                    type="submit"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message="Note archived"
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            <Alert onClose={handleClose} severity="success">
              Success!
            </Alert>
          </Snackbar>
        </>
      }
    />
  );
};

export default MyAccountBillingAddress;
