import React, { Suspense, useCallback, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Wrapper from "./wrapper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { VID } from "../../project-config";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import CreateEditShippingAddress from "./create-edit-shipping-address";

const MyAccountShippingAddress = () => {
  const [accountState, setAccountState] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalObject, setModalObject] = useState({
    visible: false
  });

  const setModalObjectCallback = useCallback(
    () => setModalObject({ visible: false }),
    [modalObject]
  );

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.GATSBY_PREVIEW_PROJECT_LINK}/myaccount.html?mode=shippingaddress&vid=${VID}&tpt=json_en`,
        { withCredentials: true, responseType: "json" }
      )
      .then(res => setAccountState(res.data))
      .then(() => setLoading(false))
      .catch(e => console.log(e));
  }, [modalObject.visible]);

  const Alert = props => <MuiAlert elevation={6} variant="filled" {...props} />;

  const handleDeleteShippingAddress = id => {
    setLoading(true);
    axios
      .get(
        `${process.env.GATSBY_PREVIEW_PROJECT_LINK}/myaccount.html?vid=${VID}&mode=delshippingaddress&addressid=${id}`,
        { withCredentials: true }
      )
      .then(() =>
        setAccountState(accountState.filter(obj => obj.addressId !== id))
      )
      .then(() => setLoading(false))
      .then(() => setOpen(true))
      .catch(e => console.log(e));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleEditModal = addressId => {
    console.log(
      "dfsd",
      accountState.find(obj => obj.addressId === addressId)
    );
    setModalObject({
      visible: true,
      address: accountState.find(obj => obj.addressId === addressId)
    });
  };

  return (
    <Wrapper
      component={
        <>
          <Typography variant="h3" gutterBottom>
            Additional Shipping Addresses
          </Typography>
          <Divider />
          <br />
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<AddIcon />}
                onClick={() =>
                  setModalObject({
                    visible: true
                  })
                }
              >
                New Shipping Address
              </Button>
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={3}>
            {loading ? (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            ) : (
              accountState?.map((obj, index) => (
                <Grid item xs={12} lg={6} key={index}>
                  <Card variant="outlined">
                    <CardHeader
                      title={obj.nickname || "No nickname"}
                      action={
                        <>
                          <IconButton
                            aria-label="Edit"
                            onClick={() => handleEditModal(obj.addressId)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="settings"
                            onClick={() =>
                              handleDeleteShippingAddress(obj.addressId)
                            }
                          >
                            <DeleteIcon color="secondary" />
                          </IconButton>
                        </>
                      }
                    />
                    <CardContent>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        <Typography
                          variant="button"
                          display="block"
                          gutterBottom
                        >
                          {obj.firstName} {obj.lastName}
                        </Typography>
                        <Typography
                          variant="button"
                          display="block"
                          gutterBottom
                        >
                          {obj.address1}
                        </Typography>
                        <Typography
                          variant="button"
                          display="block"
                          gutterBottom
                        >
                          {obj.address2}
                        </Typography>
                        <Typography
                          variant="button"
                          display="block"
                          gutterBottom
                        >
                          {obj.city}, {obj.province}, {obj.country}
                        </Typography>
                        <Typography
                          variant="button"
                          display="block"
                          gutterBottom
                        >
                          {obj.postal}
                        </Typography>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )) || (
                <>
                  <br />
                  <br />
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h4" gutterBottom>
                        You do not have any additional addresses.
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )
            )}
          </Grid>
          <Suspense fallback={<div>loading...</div>}>
            <CreateEditShippingAddress
              isOpen={modalObject.visible}
              close={setModalObjectCallback}
              addressObj={modalObject.address}
            />
          </Suspense>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message="Note archived"
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            <Alert onClose={handleClose} severity="success">
              Success!
            </Alert>
          </Snackbar>
        </>
      }
    />
  );
};

export default MyAccountShippingAddress;
