import React from "react";
import Layout from "../../other/layout";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import SidebarMenu from "../../other/my-account/sidebar-menu";

import MyAccountCustomerAddress from "./customer-address-component";
import MyAccountViewOrders from "./view-orders-component";
import MyAccountChangePassword from "./change-password-component";
import MyAccountNotifications from "./notifications-component";
import MyAccountShippingAddress from "./shipping-address-component";
import MyAccountBillingAddress from "./billing-address-component";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Quicksand"].join(",")
  }
});
const useStyles = makeStyles(theme => ({
  root: {
    padding: "1rem 0 1rem 0"
  },
  paper: {
    padding: theme.spacing(2)
  }
}));

const routes = layout => {
  switch (layout) {
    case "view-orders":
      return <MyAccountViewOrders />;
    case "notifications":
      return <MyAccountNotifications />;
    case "billing-address":
      return <MyAccountBillingAddress />;
    case "change-password":
      return <MyAccountChangePassword />;
    case "customer-address":
      return <MyAccountCustomerAddress />;
    case "shipping-address":
      return <MyAccountShippingAddress />;
    default:
      return null;
  }
};

const MyAccountLayout = ({ layout }) => {
  const classes = useStyles();

  return (
    <Layout>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <SidebarMenu />
            </Grid>
            <Grid item xs={12} lg={9}>
              {routes(layout)}
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </Layout>
  );
};

export default MyAccountLayout;
