import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import ListItemText from "@material-ui/core/ListItemText";
import NotificationsIcon from "@material-ui/icons/Notifications";
import StoreIcon from "@material-ui/icons/Store";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import Paper from "@material-ui/core/Paper";
import { Link } from "gatsby";
import { handleLogout } from "../../redux/actions/loginActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { navigate, useLocation } from "@reach/router";
import Collapse from "@material-ui/core/Collapse";
import MenuIcon from "@material-ui/icons/Menu";

const SidebarMenu = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [open, setOpen] = useState(isMobileState);
  const securityTokenState = useSelector(
    state => state.loginReducer.securityToken,
    shallowEqual
  );

  useEffect(() => {
    switch (location.pathname.replace("/my-account/", "")) {
      case "view-orders":
        setSelectedIndex(0);
        break;
      case "notifications":
        setSelectedIndex(1);
        break;
      case "billing-address":
        setSelectedIndex(2);
        break;
      case "change-password":
        setSelectedIndex(3);
        break;
      case "customer-address":
        setSelectedIndex(4);
        break;
      case "shipping-address":
        setSelectedIndex(5);
        break;
      default:
        break;
    }
  }, [location.pathname]);

  const logout = e => {
    e.preventDefault();
    dispatch(handleLogout(securityTokenState));
    navigate("/");
  };

  const handleClick = () => setOpen(!open);

  const menuItems = () => (
    <>
      <Link to="/my-account/view-orders">
        <ListItem button selected={selectedIndex === 0}>
          <ListItemIcon>
            <RemoveRedEyeIcon />
          </ListItemIcon>
          <ListItemText primary="View Orders" />
        </ListItem>
      </Link>
      <Link to="/my-account/notifications">
        <ListItem button selected={selectedIndex === 1}>
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText primary="Notifications" />
        </ListItem>
      </Link>
      <Link to="/my-account/billing-address" selected={selectedIndex === 2}>
        <ListItem button selected={selectedIndex === 2}>
          <ListItemIcon>
            <StoreIcon />
          </ListItemIcon>
          <ListItemText primary="Billing Address" />
        </ListItem>
      </Link>
      <Link to="/my-account/change-password">
        <ListItem button selected={selectedIndex === 3}>
          <ListItemIcon>
            <LockOpenIcon />
          </ListItemIcon>
          <ListItemText primary="Change Password" />
        </ListItem>
      </Link>
      <Link to="/my-account/customer-address">
        <ListItem button selected={selectedIndex === 4}>
          <ListItemIcon>
            <PermIdentityIcon />
          </ListItemIcon>
          <ListItemText primary="Customer Address" />
        </ListItem>
      </Link>
      <Link to="/my-account/shipping-address">
        <ListItem button selected={selectedIndex === 5}>
          <ListItemIcon>
            <LocalShippingIcon />
          </ListItemIcon>
          <ListItemText primary="Shipping Addresses" />
        </ListItem>
      </Link>
      <ListItem button onClick={logout}>
        <ListItemIcon>
          <PowerSettingsNewIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    </>
  );
  return (
    <Paper>
      <List component="nav" aria-label="main mailbox folders">
        {isMobileState ? (
          <>
            <ListItem button onClick={handleClick}>
              <ListItemIcon>
                <MenuIcon />
              </ListItemIcon>
              <ListItemText primary="Menu" />
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {menuItems()}
            </Collapse>
          </>
        ) : (
          menuItems()
        )}
      </List>
    </Paper>
  );
};

export default SidebarMenu;
