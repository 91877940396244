import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2)
  }
}));

const Wrapper = ({ component }) => {
  const classes = useStyles();
  return <Paper className={classes.paper}>{component}</Paper>;
};

Wrapper.propTypes = {
  component: PropTypes.element
};

export default Wrapper;
