import React from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import Wrapper from "./wrapper";

const MyAccountChangePassword = () => {
  return (
    <Wrapper
      component={
        <>
          <Typography variant="h3" gutterBottom>
            Change Password
          </Typography>
          <Divider />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <TextField
                label="Old Password"
                variant="outlined"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                label="New Password"
                variant="outlined"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                label="Confirm Password"
                variant="outlined"
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <br />
          <Divider />
          <br />
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default MyAccountChangePassword;
