import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Field, Form, Formik } from "formik";
import { TextField as FormikMUTextField } from "formik-material-ui";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { phoneRegExp } from "./utils";
import * as Yup from "yup";
import axios from "axios";
import { VID } from "../../project-config";
import { shallowEqual, useSelector } from "react-redux";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const CreateEditShippingAddress = ({ isOpen, close, addressObj }) => {
  const [accountState, setAccountState] = useState();
  const securityTokenState = useSelector(
    state => state.loginReducer.securityToken,
    shallowEqual
  );
  console.log("address", addressObj);
  useEffect(() => {
    axios
      .get(
        `${process.env.GATSBY_PREVIEW_PROJECT_LINK}/myaccount.html?mode=newshippingaddress&vid=${VID}&tpt=json_en`,
        { withCredentials: true, responseType: "json" }
      )
      .then(res => setAccountState(res.data));
  }, []);

  const Schema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .matches(/^[a-zA-Z]+$/, "Letters only")
      .required("Required"),
    middleName: Yup.string()
      .trim()
      .matches(/^[a-zA-Z]+$/, "Letters only"),
    lastName: Yup.string()
      .trim()
      .matches(/^[a-zA-Z]+$/, "Letters only")
      .required("Required"),
    city: Yup.string()
      .trim()
      .matches(/^[a-zA-Z]+$/, "Letters only")
      .required("Required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),
    nickname: Yup.string().trim(),
    company: Yup.string().trim(),
    address1: Yup.string().trim().required("Required"),
    address2: Yup.string().trim(),
    postalCode: Yup.string().trim().required("Required")
  });

  const handleModalClose = () => close();
  const handleSubmitForm = val => {
    const {
      email,
      nickname,
      firstName,
      middleName,
      lastName,
      company,
      address1,
      address2,
      provinceId,
      city,
      postalCode,
      phone,
      addressId,
      countryId
    } = val;
    let form = new FormData();
    form.append("securitytoken", securityTokenState);
    form.append("shippingaddress.nickname", nickname);
    form.append("shippingaddress.email", email);
    form.append("shippingaddress.firstname", firstName);
    form.append("shippingaddress.middlename", middleName);
    form.append("shippingaddress.lastname", lastName);
    form.append("shippingaddress.company", company);
    form.append("shippingaddress.address1", address1);
    form.append("shippingaddress.address2", address2);
    form.append("shippingaddress.countryid", countryId);
    form.append("shippingaddress.provinceid", provinceId);
    form.append("shippingaddress.city", city);
    form.append("shippingaddress.postal", postalCode);
    form.append("shippingaddress.phone", phone);
    form.append("shippingaddress.addressid", addressId);
    form.append("submit", "update");
    form.append(
      addressObj ? "_targeteditshippingaddress" : "_targetnewshippingaddress",
      `myaccount.html?vid=${VID}&mode=shippingaddress`
    );
    form.append("TODO", "");
    axios({
      method: "post",
      data: form,
      responseType: "document",
      url: addressObj
        ? `${process.env.GATSBY_PREVIEW_PROJECT_LINK}/myaccount.html?vid=${VID}&mode=editshippingaddress&addressid=${addressId}`
        : `${process.env.GATSBY_PREVIEW_PROJECT_LINK}/myaccount.html?vid=${VID}&mode=newshippingaddress`,
      withCredentials: true
    }).then(() => close());
  };

  return (
    <Dialog
      onClose={() => handleModalClose()}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <Formik
        initialValues={{
          addressId: addressObj?.addressId || "",
          email: addressObj?.email || "",
          nickname: addressObj?.nickname || "",
          company: addressObj?.company || "",
          firstName: addressObj?.firstName || "",
          middleName: addressObj?.middleName || "",
          lastName: addressObj?.lastName || "",
          address1: addressObj?.address1 || "",
          address2: addressObj?.address2 || "",
          city: addressObj?.city || "",
          postalCode: addressObj?.postal || "",
          phone: addressObj?.phone || "",
          countries: accountState?.countries || [],
          countryId: addressObj?.countryId || "",
          provinceId: addressObj?.provinceId || ""
        }}
        validationSchema={Schema}
        enableReinitialize
        onSubmit={async values => handleSubmitForm(values)}
      >
        <Form>
          <DialogTitle id="customized-dialog-title" onClose={() => close()}>
            {addressObj ? "Update " : "Create "}
            Shipping Address
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Field
                  component={FormikMUTextField}
                  label="Nickname"
                  name="nickname"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  component={FormikMUTextField}
                  label="Company"
                  name="company"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  component={FormikMUTextField}
                  label="First name"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  component={FormikMUTextField}
                  label="Middle name"
                  name="middleName"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  component={FormikMUTextField}
                  label="Last name"
                  name="lastName"
                  variant="outlined"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  component={FormikMUTextField}
                  label="Address 1"
                  name="address1"
                  variant="outlined"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  component={FormikMUTextField}
                  label="Address 2"
                  name="address2"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  component={FormikMUTextField}
                  label="City"
                  name="city"
                  variant="outlined"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <Field
                    name="countryID"
                    variant="outlined"
                    label="Country"
                    select
                    component={FormikMUTextField}
                    children={
                      accountState?.countries?.map(obj => (
                        <MenuItem key={obj.country_id} value={obj.country_id}>
                          {obj.country_name}
                        </MenuItem>
                      )) || <MenuItem value={1}>1</MenuItem>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <Field
                    variant="outlined"
                    name="provinceID"
                    label="State/Province"
                    select
                    component={FormikMUTextField}
                    children={
                      accountState?.provinces?.province_list?.map(obj => (
                        <MenuItem key={obj.province_id} value={obj.province_id}>
                          {obj.province_name}
                        </MenuItem>
                      )) || <MenuItem value={1}>1</MenuItem>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  component={FormikMUTextField}
                  label="Postal code"
                  name="postalCode"
                  variant="outlined"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  component={FormikMUTextField}
                  label="Phone"
                  name="phone"
                  variant="outlined"
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit">
              {addressObj ? "Update " : "Save"}
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default CreateEditShippingAddress;
