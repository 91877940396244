import React, { useEffect, useState, useCallback, Suspense } from "react";
import Wrapper from "./wrapper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import axios from "axios";
import { VID } from "../../project-config";
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStore";
import CardActions from "@material-ui/core/CardActions";
import ReceiptIcon from "@material-ui/icons/Receipt";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch } from "react-redux";
import {
  addToCartModalClose,
  addToLocalMiniCart,
  addToCartCheckboxProduct,
  getDeliveryOptions
} from "../../redux/actions/productAction";
import { navigate } from "@reach/router";

const ViewInvoice = React.lazy(() => import("./view-invoice"));

const MyAccountViewOrders = () => {
  const dispatch = useDispatch();

  const [accountState, setAccountState] = useState();
  const [orderObject, setOrderObject] = useState();
  const [modalObject, setModalObject] = useState({
    visible: false,
    orderId: ""
  });
  const [loading, setLoading] = useState(false);
  const setModalObjectCallback = useCallback(
    () => setModalObject({ visible: false }),
    [modalObject]
  );
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.GATSBY_PREVIEW_PROJECT_LINK}/myaccount.html?mode=vieworder&vid=${VID}&tpt=json_en`,
        { withCredentials: true, responseType: "json" }
      )
      .then(res => setAccountState(res.data))
      .then(() => setLoading(false))
      .catch(e => console.log(e));
  }, []);

  const handleAddToCart = ({ vendorId, orderId }) => {
    let orderObj = {};
    axios
      .get(
        `${process.env.GATSBY_PREVIEW_PROJECT_LINK}/myaccount.html?mode=vieworderdetail&orderid=${orderId}&vid=${VID}&tpt=json_en`,
        { withCredentials: true, responseType: "json" }
      )
      .then(res => console.log(res.data))
      .catch(e => console.log(e));

    const products = [
      {
        id: 300899,
        vid: VID,
        qty: 1,
        distributorId: vendorId
      }
    ];
    console.log("orderObject", orderObject);
    dispatch(addToCartCheckboxProduct(1, products, 0, false, vendorId));
  };

  return (
    <Wrapper
      component={
        <>
          <Typography variant="h3" gutterBottom>
            View Orders
          </Typography>
          <Divider />
          <br />
          <Grid container spacing={3}>
            {loading ? (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            ) : (
              accountState?.map(obj => (
                <Grid item xs={12} lg={6} key={obj.orderId}>
                  <Card variant="outlined">
                    <CardHeader
                      action={
                        <IconButton
                          aria-label="settings"
                          onClick={() => handleAddToCart(obj)}
                        >
                          <Tooltip title="Order again" placement="top" arrow>
                            <LocalGroceryStoreIcon />
                          </Tooltip>
                        </IconButton>
                      }
                      title={`ID: ${obj.orderNum}`}
                      subheader={`Date of Order: ${obj.orderDate}`}
                    />
                    <CardContent>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        <Typography
                          variant="button"
                          display="block"
                          gutterBottom
                        >
                          Email: {obj.orderEmail}
                        </Typography>
                        <Typography
                          variant="button"
                          display="block"
                          gutterBottom
                        >
                          Ext. Price: {obj.orderTotal}
                        </Typography>
                        <Typography
                          variant="button"
                          display="block"
                          gutterBottom
                        >
                          Order Status: {obj.orderStatus}
                        </Typography>
                      </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                      <IconButton
                        aria-label="settings"
                        onClick={() =>
                          setModalObject({
                            visible: true,
                            orderId: obj.orderId
                          })
                        }
                      >
                        <Tooltip title="Invoice" placement="top" arrow>
                          <ReceiptIcon />
                        </Tooltip>
                      </IconButton>
                      <IconButton aria-label="settings">
                        <Tooltip title="Rate seller" placement="top" arrow>
                          <ThumbsUpDownIcon />
                        </Tooltip>
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              )) || (
                <>
                  <br />
                  <br />
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h4" gutterBottom>
                        You do not have any orders yet.
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )
            )}
          </Grid>
          <Suspense fallback={<div>loading...</div>}>
            <ViewInvoice
              isOpen={modalObject.visible}
              close={setModalObjectCallback}
              orderId={modalObject.orderId}
            />
          </Suspense>
        </>
      }
    />
  );
};

export default MyAccountViewOrders;
